import { graphql } from 'gatsby'
import React from 'react'

import { HighlightSection, Layout, Section, SEO } from '../components'
import { JumbotronEn } from '../components/section/JumbotronEn'
import { srOnly } from '../styles'
import { Edges, Markdown } from '../types'

export const query = graphql`
  query SoliEn {
    allMarkdownRemark(sort: {order: ASC, fields: fileAbsolutePath}, filter: {fileAbsolutePath: {regex: "/sections/i18n/en/[\\w-]+\\.md$/"}}) {
      edges {
        node {
          html
          frontmatter {
            title
            color
            colorIndex
            image {
              mobile: childImageSharp {
                fluid(maxWidth: 1000, maxHeight: 1000, cropFocus: CENTER, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
              desktop: childImageSharp {
                fluid(maxWidth: 2000, maxHeight: 2000, cropFocus: CENTER, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
              desktopLarge: childImageSharp {
                fluid(maxWidth: 2000, maxHeight: 2854, cropFocus: CENTER, srcSetBreakpoints: []) {
                  presentationWidth
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

interface SoliEnPageProps {
  location: Location
  data: {
    allMarkdownRemark: {
      edges: Edges<Markdown>
    }
  }
}

export const SoliEnPage = ({ data, location }: SoliEnPageProps) => {
  const currPath = location.pathname
  const pageColor = 'red'
  const title = 'A Solidarity Association for all Leftists'
  return (
    <Layout currPath={currPath} pageColor={pageColor} language="en">
      <SEO title={title} />
      <h1 css={srOnly}>{title}</h1>
      <Section index={0} renderBg={true} color="red" colorIndex={0} headerOffset={false}>
        <JumbotronEn />
      </Section>
      {data.allMarkdownRemark.edges.map(
        (
          {
            node: {
              html,
              frontmatter: { desktopImage, image, color, colorIndex, title }
            }
          },
          i
        ) => (
          <Section
            renderBg={true}
            color={color!}
            colorIndex={colorIndex}
            headerOffset={false}
            key={i}
            index={i + 1}
          >
            <HighlightSection
              title={title!}
              color={color!}
              image={image!}
              content={html}
              desktopImage={desktopImage}
              imageLeft={i % 2 === 0}
            />
          </Section>
        )
      )}
    </Layout>
  )
}

export default SoliEnPage
