import { css } from '@emotion/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { Parallax } from 'react-scroll-parallax'

import { Button, Container, Html, SlantedText } from '..'
import {
  getLogoColors,
  largeText,
  makeContentStyle,
  textContentLineLength,
  desktopBreakpoint,
  srOnly
} from '../../styles'
import { AllMarkdown } from '../../types'
import { ResponsiveImage } from '../images'
import { cx } from '../../utils/cx'

const style = {
  wrapper: css`
    position: relative;
    min-height: 80vmax;
    display: flex;
    align-items: flex-end;
    > * {
      flex-grow: 1;
    }
    @media ${desktopBreakpoint} {
      min-height: 80vh;
    }
  `,
  imageWrapper: css`
    z-index: -1;
    bottom: 15em;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    transform: translateY(-10%);
    @media ${desktopBreakpoint} {
      transform: translateY(-20%);
    }
  `,
  imageWrapperTeaser: css`
    bottom: 20em;
  `,
  image: css`
    position: absolute;
    left: 0;
    right: 0;
    @media ${desktopBreakpoint} {
      top: 50%;
      transform: translateY(-50%);
    }
  `,
  contentOuter: css`
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-bottom: 2em;
    padding-top: 20em;
    @media ${desktopBreakpoint} {
      padding-bottom: 6em;
    }
  `,
  content: css`
    max-width: ${textContentLineLength};
  `,
  ctaWrapper: css`
    margin-top: 1em;
  `
}

const query = graphql`
query JumbotronEn {
  jumbotron: allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___date]}, filter: {fileAbsolutePath: {regex: "/jumbotron/i18n/en/[\\w-]+\\.md$/"}}) {
    edges {
      node {
        html
        frontmatter {
          date(formatString: "D.M.YYYY")
          title
          summary
          ctaLabel
          ctaPath
          image {
            mobile: childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 1333, cropFocus: CENTER) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
            desktopLarge: childImageSharp {
              fluid(maxWidth: 2854, maxHeight: 2141, cropFocus: CENTER) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
          }
          desktopImage {
            mobile: childImageSharp {
              fluid(maxWidth: 1000, maxHeight: 1333, cropFocus: CENTER) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
            desktop: childImageSharp {
              fluid(maxWidth: 2000, maxHeight: 2000, cropFocus: CENTER) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
            desktopLarge: childImageSharp {
              fluid(maxWidth: 2854, maxHeight: 2141, cropFocus: CENTER) {
                presentationWidth
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
}
`

interface JumbotronEnProps {
  teaser: boolean
}

export const JumbotronEn = ({ teaser }: JumbotronEnProps) => {
  const data = useStaticQuery<{ jumbotron: AllMarkdown }>(query)
  if (!data) {
    return null
  }
  const content = data.jumbotron.edges[0].node
  return (
    <div css={style.wrapper}>
      <h1 css={srOnly}>{content.frontmatter.title!.join(' ')}</h1>
      <div css={cx([[true, style.imageWrapper], [teaser, style.imageWrapperTeaser]])}>
        <div css={style.image}>
          <Parallax y={[-30, 30]}>
            <ResponsiveImage
              alt={content.frontmatter.title!.join(' ')}
              image={content.frontmatter.image!}
              desktopImage={content.frontmatter.desktopImage}
            />
          </Parallax>
        </div>
      </div>
      <Container>
        <div css={style.contentOuter}>
          <div css={[style.content, largeText]}>
            {teaser ? (
              <SlantedText responsive={true}>
                <div css={makeContentStyle('red')}>
                  {content.frontmatter.summary && <Html>{content.frontmatter.summary}</Html>}
                </div>
                {content.frontmatter.ctaLabel && content.frontmatter.ctaPath && (
                  <div css={style.ctaWrapper}>
                    <Button
                      color={getLogoColors('red', 0)[0]}
                      label={content.frontmatter.ctaLabel}
                      path={content.frontmatter.ctaPath}
                    />
                  </div>
                )}
              </SlantedText>
            ) : (
              <SlantedText responsive={true}>
                <div css={makeContentStyle('red')}>
                  {content.html && <Html>{content.html}</Html>}
                </div>
              </SlantedText>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

JumbotronEn.defaultProps = {
  teaser: false
}
